<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        <b>
          The questions and tables below are an example of risk assessment and management of a
          laboratory activity. Not only is the chemical hazard important, but so are the equipment
          and the procedural steps.
        </b>
      </p>
      <p class="mb-2">
        Heptane is one of the eluents (organic solvents) used in thin layer chromatography many
        times in this course. Think about how heptane is utilized during this project's experiment:
        the amount, the vessel it's placed in, its transport from the lab supply to where you use
        it, the personal protective equipment used in the lab, etc. Use this information and the SDS
        of heptane to fill in the tables below.
      </p>
      <p class="mb-3">What are heptane's hazard classes? How severe is each hazard?</p>

      <table class="mb-3">
        <thead>
          <tr>
            <th>GHS Classification</th>
            <th>GHS Symbol</th>
            <th>Hazard Category</th>
            <th>Useful info from SDS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Flammable Liquid</td>
            <td>
              <v-select
                v-model="inputs.input1"
                style="max-width: 250px"
                :items="items1"
                item-text="text"
                item-value="value"
                label="1a"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input2"
                style="max-width: 250px"
                :items="items2"
                item-text="text"
                item-value="value"
                label="1b"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input3"
                style="max-width: 250px"
                :items="items3"
                item-text="text"
                item-value="value"
                label="1c"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Skin Irritation</td>
            <td>
              <v-select
                v-model="inputs.input4"
                style="max-width: 250px"
                :items="items1"
                item-text="text"
                item-value="value"
                label="2a"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input5"
                style="max-width: 250px"
                :items="items2"
                item-text="text"
                item-value="value"
                label="2b"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input6"
                style="max-width: 250px"
                :items="items4"
                item-text="text"
                item-value="value"
                label="2c"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Central nervous system toxicity, single exposure</td>
            <td>
              <v-select
                v-model="inputs.input7"
                style="max-width: 250px"
                :items="items1"
                item-text="text"
                item-value="value"
                label="3a"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input8"
                style="max-width: 250px"
                :items="items2"
                item-text="text"
                item-value="value"
                label="3b"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input9"
                style="max-width: 250px"
                :items="items5"
                item-text="text"
                item-value="value"
                label="3c"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Aspiration hazard</td>
            <td>
              <v-select
                v-model="inputs.input10"
                style="max-width: 250px"
                :items="items1"
                item-text="text"
                item-value="value"
                label="4a"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input11"
                style="max-width: 250px"
                :items="items2"
                item-text="text"
                item-value="value"
                label="4b"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input12"
                style="max-width: 250px"
                :items="items6"
                item-text="text"
                item-value="value"
                label="4c"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Aquatic hazard</td>
            <td>
              <v-select
                v-model="inputs.input13"
                style="max-width: 250px"
                :items="items1"
                item-text="text"
                item-value="value"
                label="5a"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input14"
                style="max-width: 250px"
                :items="items2"
                item-text="text"
                item-value="value"
                label="5b"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input15"
                style="max-width: 250px"
                :items="items6"
                item-text="text"
                item-value="value"
                label="5c"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </table>

      <p class="mb-4 pl-3">
        <v-img style="max-width: 500px" src="/img/assignments/UCIrvine/1LDQ4S1Q10.png" />
      </p>

      <p class="mb-2">
        What is the likelihood of exposure to heptane by the various routes (inhalation, ingestion,
        dermal contact)? What are the reasonable steps to take to avoid exposure?
      </p>

      <table class="mb-3">
        <thead>
          <tr>
            <th>Exposure Route</th>
            <th>Useful Info in SDS</th>
            <th>Probability of happening</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Inhalation</td>
            <td>
              <v-select
                v-model="inputs.input16"
                style="max-width: 250px"
                :items="items7"
                item-text="text"
                item-value="value"
                label="6a"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input17"
                style="max-width: 250px"
                :items="items8"
                item-text="text"
                item-value="value"
                label="6b"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Dermal (eyes or skin)</td>
            <td>
              <v-select
                v-model="inputs.input18"
                style="max-width: 250px"
                :items="items9"
                item-text="text"
                item-value="value"
                label="7a"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input19"
                style="max-width: 250px"
                :items="items10"
                item-text="text"
                item-value="value"
                label="7b"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Ingestion</td>
            <td>
              <v-select
                v-model="inputs.input20"
                style="max-width: 250px"
                :items="items13"
                item-text="text"
                item-value="value"
                label="8a"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input21"
                style="max-width: 250px"
                :items="items11"
                item-text="text"
                item-value="value"
                label="8b"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Injection</td>
            <td>
              <v-select
                v-model="inputs.input22"
                style="max-width: 250px"
                :items="items14"
                item-text="text"
                item-value="value"
                label="9a"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input23"
                style="max-width: 250px"
                :items="items12"
                item-text="text"
                item-value="value"
                label="9b"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </table>

      <p class="mb-2">
        Heptane and
        <u>hexane</u>
        have many similar chemical properties and hexane could be used in place of heptane for the
        TLC procedure. Why is heptane used for the TLC process instead of hexane?
      </p>

      <v-radio-group v-model="inputs.input24" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="item in itemsMC"
          :key="'pt-2-' + item.value"
          class="my-1 ml-3"
          :value="item.value"
        >
          <template #label>
            <stemble-latex :content="item.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LDQ4S1Q10',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
        input10: null,
        input11: null,
        input12: null,
        input13: null,
        input14: null,
        input15: null,
        input16: null,
        input17: null,
        input18: null,
        input19: null,
        input20: null,
        input21: null,
        input22: null,
        input23: null,
        input24: null,
      },
      items1: [
        {text: '$\\text{I}$', value: 'I'},
        {text: '$\\text{II}$', value: 'II'},
        {text: '$\\text{III}$', value: 'III'},
        {text: '$\\text{IV}$', value: 'IV'},
      ],
      items2: [
        {text: '$1$', value: 'one'},
        {text: '$2$', value: 'two'},
        {text: '$3$', value: 'three'},
        {text: '$4$', value: 'four'},
      ],
      items3: [
        {text: 'flash point', value: 'flash'},
        {text: 'autoignition', value: 'auto'},
        {text: 'extinguishing media', value: 'ext'},
        {text: 'handling precautions', value: 'handling'},
        {text: 'three of these', value: 'three'},
        {text: 'all of these', value: 'all'},
      ],
      items4: [
        {text: 'first aid information', value: 'firstAid'},
        {text: 'hygiene measures', value: 'hygiene'},
        {text: 'skin protection', value: 'skin'},
        {text: 'body protection', value: 'body'},
        {text: 'three of these', value: 'three'},
        {text: 'all of these', value: 'all'},
      ],
      items5: [
        {text: 'viscosity', value: 'viscosity'},
        {text: 'ecological information', value: 'eco'},
        {text: 'vapor pressure', value: 'vapor'},
        {text: 'melting point', value: 'melting'},
        {text: 'three of these', value: 'three'},
        {text: 'all of these', value: 'all'},
      ],
      items6: [
        {text: 'suitable extinguishing media', value: 'suitable'},
        {text: 'storage conditions', value: 'storage'},
        {text: 'skin protection', value: 'skin'},
        {text: 'first aid measures', value: 'firstAid'},
        {text: 'three of these', value: 'three'},
        {text: 'all of these', value: 'all'},
      ],
      items7: [
        {text: 'first aid measures', value: 'firstAid'},
        {text: 'storage conditions', value: 'storage'},
        {text: 'respiratory protection', value: 'resp'},
        {text: 'vapor pressure', value: 'vapor'},
        {text: 'three of these', value: 'three'},
        {text: 'all of these', value: 'all'},
      ],
      items8: [
        {text: 'low, heptane was always used in the fume hood', value: 'low'},
        {
          text: 'moderate, heptane transported out of fume hood, but usually capped in TLC chamber',
          value: 'moderate',
        },
        {
          text: 'high, heptane was heated, increasing its concentration in the lab air',
          value: 'high',
        },
      ],
      items9: [
        {text: 'LD50 dermal values', value: 'dermal'},
        {text: 'persistence and degradability', value: 'persistence'},
        {text: 'target organ toxicity results', value: 'organ'},
        {text: 'incompatible materials', value: 'materials'},
        {text: 'three of these', value: 'three'},
        {text: 'all of these', value: 'all'},
      ],
      items10: [
        {text: 'low, heptane was never poured from one vessel to another', value: 'low'},
        {
          text: 'moderate, a small amount of heptane was poured with gloved hands',
          value: 'moderate',
        },
        {
          text: 'high, a large quantity of heptane was transferred, no gloves were worn',
          value: 'high',
        },
      ],
      items11: [
        {text: 'low, eating in lab prohibited, use of plastic wrap on computer', value: 'low'},
        {text: 'moderate, biting fingernails may cause ingestion', value: 'moderate'},
        {text: 'high, laboratory procedures may result in exposure by this route', value: 'high'},
      ],
      items12: [
        {text: 'low, no glass apparatus used in this process', value: 'low'},
        {text: 'moderate, glass capillaries are small and brittle', value: 'moderate'},
        {text: 'high, glassware breakage highly possible with TLC', value: 'high'},
      ],
      items13: [
        {text: 'advice for firefighters', value: 'fire'},
        {text: 'advice for safe handling', value: 'safe'},
        {text: 'respiratory protection', value: 'resp'},
        {text: 'LD50 Oral values for rate', value: 'rate'},
        {text: 'three of these', value: 'three'},
        {text: 'all of these', value: 'all'},
      ],
      items14: [
        {text: 'skin protection', value: 'skin'},
        {text: 'viscosity', value: 'viscosity'},
        {text: 'hazardous decomposition products', value: 'hazard'},
        {text: 'all of these', value: 'all'},
        {text: 'none of these', value: 'none'},
      ],
      itemsMC: [
        {text: 'Hexane has more GHS pictograms', value: 'more'},
        {text: 'Hexane is a reproductive toxicant, heptane is not.', value: 'toxicant'},
        {text: 'Hexane has a high vapor pressure below room temperature', value: 'pressure'},
        {text: "Hexane's flash point is at a lower temperature than heptane's", value: 'flash'},
        {text: 'three of these', value: 'three'},
        {text: 'all of these', value: 'all'},
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
