var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-2"},[_c('b',[_vm._v(" The questions and tables below are an example of risk assessment and management of a laboratory activity. Not only is the chemical hazard important, but so are the equipment and the procedural steps. ")])]),_c('p',{staticClass:"mb-2"},[_vm._v(" Heptane is one of the eluents (organic solvents) used in thin layer chromatography many times in this course. Think about how heptane is utilized during this project's experiment: the amount, the vessel it's placed in, its transport from the lab supply to where you use it, the personal protective equipment used in the lab, etc. Use this information and the SDS of heptane to fill in the tables below. ")]),_c('p',{staticClass:"mb-3"},[_vm._v("What are heptane's hazard classes? How severe is each hazard?")]),_c('table',{staticClass:"mb-3"},[_c('thead',[_c('tr',[_c('th',[_vm._v("GHS Classification")]),_c('th',[_vm._v("GHS Symbol")]),_c('th',[_vm._v("Hazard Category")]),_c('th',[_vm._v("Useful info from SDS")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Flammable Liquid")]),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"1a"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"1b"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items3,"item-text":"text","item-value":"value","label":"1c"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1)]),_c('tr',[_c('td',[_vm._v("Skin Irritation")]),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"2a"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"2b"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items4,"item-text":"text","item-value":"value","label":"2c"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}})],1)]),_c('tr',[_c('td',[_vm._v("Central nervous system toxicity, single exposure")]),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"3a"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input7),callback:function ($$v) {_vm.$set(_vm.inputs, "input7", $$v)},expression:"inputs.input7"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"3b"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input8),callback:function ($$v) {_vm.$set(_vm.inputs, "input8", $$v)},expression:"inputs.input8"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items5,"item-text":"text","item-value":"value","label":"3c"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input9),callback:function ($$v) {_vm.$set(_vm.inputs, "input9", $$v)},expression:"inputs.input9"}})],1)]),_c('tr',[_c('td',[_vm._v("Aspiration hazard")]),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"4a"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input10),callback:function ($$v) {_vm.$set(_vm.inputs, "input10", $$v)},expression:"inputs.input10"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"4b"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input11),callback:function ($$v) {_vm.$set(_vm.inputs, "input11", $$v)},expression:"inputs.input11"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items6,"item-text":"text","item-value":"value","label":"4c"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input12),callback:function ($$v) {_vm.$set(_vm.inputs, "input12", $$v)},expression:"inputs.input12"}})],1)]),_c('tr',[_c('td',[_vm._v("Aquatic hazard")]),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"5a"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input13),callback:function ($$v) {_vm.$set(_vm.inputs, "input13", $$v)},expression:"inputs.input13"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"5b"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input14),callback:function ($$v) {_vm.$set(_vm.inputs, "input14", $$v)},expression:"inputs.input14"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items6,"item-text":"text","item-value":"value","label":"5c"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input15),callback:function ($$v) {_vm.$set(_vm.inputs, "input15", $$v)},expression:"inputs.input15"}})],1)])])]),_c('p',{staticClass:"mb-4 pl-3"},[_c('v-img',{staticStyle:{"max-width":"500px"},attrs:{"src":"/img/assignments/UCIrvine/1LDQ4S1Q10.png"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" What is the likelihood of exposure to heptane by the various routes (inhalation, ingestion, dermal contact)? What are the reasonable steps to take to avoid exposure? ")]),_c('table',{staticClass:"mb-3"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Exposure Route")]),_c('th',[_vm._v("Useful Info in SDS")]),_c('th',[_vm._v("Probability of happening")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Inhalation")]),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items7,"item-text":"text","item-value":"value","label":"6a"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input16),callback:function ($$v) {_vm.$set(_vm.inputs, "input16", $$v)},expression:"inputs.input16"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items8,"item-text":"text","item-value":"value","label":"6b"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input17),callback:function ($$v) {_vm.$set(_vm.inputs, "input17", $$v)},expression:"inputs.input17"}})],1)]),_c('tr',[_c('td',[_vm._v("Dermal (eyes or skin)")]),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items9,"item-text":"text","item-value":"value","label":"7a"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input18),callback:function ($$v) {_vm.$set(_vm.inputs, "input18", $$v)},expression:"inputs.input18"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items10,"item-text":"text","item-value":"value","label":"7b"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input19),callback:function ($$v) {_vm.$set(_vm.inputs, "input19", $$v)},expression:"inputs.input19"}})],1)]),_c('tr',[_c('td',[_vm._v("Ingestion")]),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items13,"item-text":"text","item-value":"value","label":"8a"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input20),callback:function ($$v) {_vm.$set(_vm.inputs, "input20", $$v)},expression:"inputs.input20"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items11,"item-text":"text","item-value":"value","label":"8b"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input21),callback:function ($$v) {_vm.$set(_vm.inputs, "input21", $$v)},expression:"inputs.input21"}})],1)]),_c('tr',[_c('td',[_vm._v("Injection")]),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items14,"item-text":"text","item-value":"value","label":"9a"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input22),callback:function ($$v) {_vm.$set(_vm.inputs, "input22", $$v)},expression:"inputs.input22"}})],1),_c('td',[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"items":_vm.items12,"item-text":"text","item-value":"value","label":"9b"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input23),callback:function ($$v) {_vm.$set(_vm.inputs, "input23", $$v)},expression:"inputs.input23"}})],1)])])]),_c('p',{staticClass:"mb-2"},[_vm._v(" Heptane and "),_c('u',[_vm._v("hexane")]),_vm._v(" have many similar chemical properties and hexane could be used in place of heptane for the TLC procedure. Why is heptane used for the TLC process instead of hexane? ")]),_c('v-radio-group',{staticClass:"mb-0 ml-6",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.input24),callback:function ($$v) {_vm.$set(_vm.inputs, "input24", $$v)},expression:"inputs.input24"}},_vm._l((_vm.itemsMC),function(item){return _c('v-radio',{key:'pt-2-' + item.value,staticClass:"my-1 ml-3",attrs:{"value":item.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('stemble-latex',{attrs:{"content":item.text}})]},proxy:true}],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }